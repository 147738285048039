<nav class="navbar navbar-expand-lg fixed-top">

    <div class="container-fluid">

        <a class="navbar-brand" [routerLink]="['/more']">
            <h3 class="sr-only">Neat</h3>
            <img id="img-logo-color" src="assets/images/navbar/lg_neat_2023.svg" alt="Neat">
        </a>

        <div class="navbar-buttons">

            <div class="nav-cta" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a id="navbar-login" class="neat-button secondary" href="https://app.neatpagos.com/dashboard" title="Iniciar sesión">Iniciar sesión</a>
            </div>

            <div class="nav-cta d-none d-lg-block" *ngIf="!router.url.includes('registrar')" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a id="let-us-begin-navbar" href="https://app.neatpagos.com/registrar" class="neat-button primary" title="Empezar">Empezar</a>
            </div> 

            <button class="navbar-toggler" type="button" data-toggle="collapse" [ngClass]="isMenuCollapse ? 'collapse show' : 'hide collapsed'" (click)="isMenuCollapse = !isMenuCollapse"
            data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="icon-bar top-bar"></span>
                <span class="icon-bar middle-bar"></span>
                <span class="icon-bar bottom-bar"></span>
                <span class="sr-only">Menú</span>
            </button>
        
        </div>

        <div class="navbar-collapse" [ngClass]="isMenuCollapse ? 'show collapsed' : 'hide collapse'" id="navbarNavDropdown">

            <ul class="navbar-nav">
                <li class="nav-item mx-lg-3" (click)="isMenuCollapse = false" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a id="go-to-about-us-navbar" class="nav-link" [routerLink]="['/more/nosotros']" title="Equipo">Equipo</a>
                </li>
                <li class="nav-item mx-lg-3" (click)="isMenuCollapse = false" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a id="go-to-neatclub-navbar" class="nav-link" [routerLink]="['/neatclub']" title="NeatClub">NeatClub</a>
                </li>
                <li class="nav-item mx-lg-3" (click)="isMenuCollapse = false" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a id="go-to-neat-journal-navbar" class="nav-link" [routerLink]="['/blog/articulos']" title="Aprende">Aprende</a>
                </li>
                <li class="nav-item mx-lg-3">
                    <a id="go-to-faqs-navbar" class="nav-link" target="_blank" href="https://ayuda.neatpagos.com/es/">Ayuda</a>
                </li>
                <div class="nav-item d-none show-xs-phone">
                    <a id="navbar-login-mobile" class="nav-link" href="https://app.neatpagos.com/dashboard" title="Iniciar sesión">Iniciar sesión</a>
                </div>
                <li class="nav-item d-block d-lg-none">
                    <a id="let-us-begin-navbar-mobile" class="nav-link" href="https://app.neatpagos.com/registrar" title="Regístrate en Neat">Regístrate en Neat</a>
                </li>
            </ul>

        </div>

    </div>
</nav>