import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
export const version = require('./../../../../../package.json').version;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  year: number;
  isMobile: boolean;
  devMode: boolean;
  version: string;
  constructor(
    public router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.year = new Date().getFullYear();
  }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && this.document) {
      this.devMode = isDevMode();
      this.version = version;
      if (this.document.getElementById("linkedin")) {
        this.document.getElementById("linkedin").innerHTML = this.document.getElementById("linkedin-scripts").innerHTML;
      }
    } 
  }

}
