import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BankHoursModalComponent } from '@shared/modals/bank-hours-modal/bank-hours-modal.component';
import { ReferredWelcomeModalComponent } from '@shared/modals/referred-welcome-modal/referred-welcome-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(
    public dialog: MatDialog, 
    public dialogRef: MatDialogRef<any>, 
    ) {}

  openReferredWelcomeModal(name: string, lastname: string) {
    this.dialog.open(ReferredWelcomeModalComponent, {
      maxWidth: '100%',
      minWidth: '50%',
      data: {
        name,
        lastname
      },
    });
  }

  openBankHoursModal() {
    this.dialog.open(BankHoursModalComponent, {
      maxWidth: '100%',
      minWidth: '50%',
    });
  }


  closeAll() {
    this.dialog.closeAll();
  }
}
