import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  constructor() {}

  recordError(error: Error, file: string, functionName: string, customMessage?: string, level?: Sentry.Severity): void {
    Sentry.withScope( (scope) => {
      scope.setExtra('file', file);
      scope.setExtra('functionName', functionName);
      scope.setExtra('customMessage', customMessage);
      if (level) {
        scope.setLevel(level);
      }
      Sentry.captureException(error);
    });
  }

}
