import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplacePipe } from './replace.pipe';
import { MdToHtmlPipe } from './md-to-html.pipe';
import { ReadingTimePipe } from './reading-time.pipe';
import { ContentResumePipe } from './content-resume.pipe';
import { LocalDatePipe } from './local-date.pipe';
import { TruncateTogglePipe } from './truncate-toggle.pipe';
import { ReplaceAllPipe } from './replace-all.pipe';
import { StylePipe } from './style.pipe';
import { ClpCurrencyPipe } from './clp-currency.pipe';

@NgModule({
  declarations: [
    ReplacePipe,
    MdToHtmlPipe,
    ReadingTimePipe,
    ContentResumePipe,
    LocalDatePipe,
    TruncateTogglePipe,
    ReplaceAllPipe,
    StylePipe,
    ClpCurrencyPipe,
  ],
  imports: [CommonModule],
  exports: [
    ReplacePipe,
    MdToHtmlPipe,
    ReadingTimePipe,
    ContentResumePipe,
    LocalDatePipe,
    TruncateTogglePipe,
    ReplaceAllPipe,
    StylePipe,
    ClpCurrencyPipe,
  ]
})
export class PipesModule {}
