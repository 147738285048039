import { environment } from './../../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { Integrations as ApmIntegrations } from '@sentry/apm';
import * as Sentry from '@sentry/browser';

  Sentry.init(Object.assign(
    environment.sentry,
    {integrations: [new ApmIntegrations.Tracing() as any]})
  );
  @Injectable()
  export class SentryErrorHandler implements ErrorHandler {
    constructor() {}
    handleError(error) {
      const eventId = Sentry.captureException(error.originalError || error);
      if (environment.sentry.active) {
        Sentry.showReportDialog({ eventId });
      }
    }
  }

@NgModule({
  declarations: [],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,
  ],
  exports: [],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LcqerUUAAAAAP4qyBEPTqYEjpV6kS6sfouZr5Sx'
    },
  ]
})
export class CoreModule {}
