
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoPageFoundComponent } from './components/no-page-found/no-page-found.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PipesModule } from './pipes/pipes.module';
import { TruncateToggleComponent } from './elements/truncate/truncate-toggle/truncate-toggle.component';
import { ReferredWelcomeModalComponent } from './modals/referred-welcome-modal/referred-welcome-modal.component';
import { JobsComponent } from './elements/jobs/jobs.component';
import { PreloadingComponent } from './components/preloading/preloading.component';
import { BlogNavbarComponent } from './components/blog-navbar/blog-navbar.component';
import { BlogFooterComponent } from './components/blog-footer/blog-footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BankHoursModalComponent } from './modals/bank-hours-modal/bank-hours-modal.component';
import { LoadingLandingComponent } from './components/loading-landing/loading-landing.component';
import { HeroImageComponent } from './components/hero-image/hero-image.component';
import { EntityCardComponent } from './components/entity-card/entity-card.component';

@NgModule({
  declarations: [
    NoPageFoundComponent,
    FooterComponent,
    NavbarComponent,
    TruncateToggleComponent,
    ReferredWelcomeModalComponent,
    JobsComponent,
    PreloadingComponent,
    BlogNavbarComponent,
    BlogFooterComponent,
    BankHoursModalComponent,
    LoadingLandingComponent,
    HeroImageComponent,
    EntityCardComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    PipesModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  exports: [
    NoPageFoundComponent,
    FooterComponent,
    NavbarComponent,
    TruncateToggleComponent,
    ReferredWelcomeModalComponent,
    BankHoursModalComponent,
    JobsComponent,
    PreloadingComponent,
    BlogNavbarComponent,
    BlogFooterComponent,
    HeroImageComponent,
    EntityCardComponent,
  ],
  entryComponents: [
    ReferredWelcomeModalComponent,
    BankHoursModalComponent,
  ]
})
export class SharedModule {}
