import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-referred-welcome-modal',
  templateUrl: './referred-welcome-modal.component.html',
  styleUrls: ['./referred-welcome-modal.component.scss']
})
export class ReferredWelcomeModalComponent implements OnInit {
  name: string;
  lastname: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReferredWelcomeModalComponent>,
    private router: Router,
    ) { 
      this.name = this.data.name;
      this.lastname = this.data.lastname;
    }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
