<div class="entity-card num-{{id}}">
  <div class="box">
    <img 
      src="assets/images/entity-cards/im_entity_card_{{id}}.png" 
      srcset="assets/images/entity-cards/im_entity_card_{{id}}.png 1x, assets/images/entity-cards/im_entity_card_{{id}}@2x.png 2x"
      [alt]="name"
    >
    <span>
      {{name}}    
    </span>
  </div>
</div>