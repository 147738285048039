<section id="referrals-section" class="row animated fadeIn">
    <div class="col-12 col-md-12 mx-auto">
        <mat-card class="mat-elevation-z0 neumorphic">
            <mat-card-content class="px-lg-3">
                <span class="close" (click)="onNoClick()"><mat-icon class="c-pointer">close</mat-icon></span>
                <ng-container>
                    <div class="info-container">
                        <div class="d-block text-center">
                            <div>
                                <img class="img-fluid w-100" src="assets/images/faqs/paymentInfo.png" alt="Horas hábiles">
                            </div>
                            <a mat-raised-button class="custom-btn px-4" style="width: 50px;" (click)="onNoClick()" href="javascript:void(0);" color="primary">OK</a>
                        </div>
                    </div>
                </ng-container>
            </mat-card-content>
        </mat-card>
    </div>
</section>
