import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
declare global {
  interface Window {
    Intercom(
      arg0: string,
      arg1?: {
        app_id: string;
      }
    );
  }
}
@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) {}
  initIntercom() {
    if (isPlatformBrowser(this.platformId)) {
      window.Intercom('boot', {
        app_id: 'kvqogpa7'
      });
    }
  }
}
