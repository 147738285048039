import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ErrorHandlerService } from '@services/error-handler.service';
import { SeoService } from '@shared/services/seo.service';
import { IntercomService } from '@shared/services/intercom.service';
import { DevicesDetectorService } from '@shared/services/devices-detector.service';
import { isPlatformBrowser } from '@angular/common';
import { CloudService } from '@shared/services/cloud.service';
import { SweetalertService } from '@shared/services/sweetalert.service';
declare var heap: any;

export enum OriginSurvey {
  nonEntity2ndTouchPoint = 'nonEntity2ndTouchPoint',
}

export interface EmailSurveyRequestObject {
  origin: OriginSurvey,
  questionText: string,
  questionResponse: string,
  userId: string,
  templateEmailId: string,
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  titulo = 'neat-content';
  activeLang: string;
  emailResponse:{
    origin: OriginSurvey,
    questionText: string,
    questionResponse: string,
    userId: string,
    templateEmailId: string,
  };
  constructor(
    private route: ActivatedRoute,
    private errorService: ErrorHandlerService,
    private seoService: SeoService,
    private intercomService: IntercomService,
    private deviceDetectorService: DevicesDetectorService,
    public cloudService: CloudService,
    private swalService: SweetalertService,
    @Inject(PLATFORM_ID) private platformId: Object, private renderer: Renderer2,
  ) {
    this.seoService.initializeTags();
    this.intercomService.initIntercom();
    this.route.queryParams.subscribe(params => {
      if(params['from']){
        try{
          heap.addUserProperties({
            'comesFrom': params['from']
          });
        } catch(e){
          this.errorService.recordError(e, 'app.component.ts',
          `heap.addUserProperties()', 'Error al asignar heap property`);
        }
      }

      if(params['origin'] && params['questionText'] && params['questionResponse'] && params['userId'] && params['templateEmailId']){
        const emailResponse: EmailSurveyRequestObject = {
          origin: params.origin,
          questionText: params.questionText,
          questionResponse: params.questionResponse,
          userId: params.userId,
          templateEmailId: params.templateEmailId,
        }
        const res = this.cloudService.saveSurveyResponse(emailResponse);
        
        res.subscribe(() => {
          this.swalService.swalSuccess('Gracias por tu tiempo y respuesta 😄', `Que tengas un gran día. Javier, CTO de neatpagos.com`);
        }, error => {
            this.errorService.recordError(error, 'app.component.ts','saveSurveyResponse', `Error to save survey response from email ${emailResponse.origin}`);
        });
      }
    });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.deviceDetectorService.isMobile();
      this.deviceDetectorService.isTablet();
      this.deviceDetectorService.isDesktop();
    }
  }
}
