<section class="hero-container" [ngClass]="routers.url === '/more' || routers.url === '/' || routers.url.includes('/?') || routers.url.includes('?') ? 'view-home' : 'd-none'">
    <div class="info">
        <div class="copy">
            <h1 class="title">Haz tus pagos mensuales con tarjeta <span>y acumula sus beneficios</span></h1>
            <div class="description">
                <p class="text-top">
                    Además de tus cuentas básicas, puedes pagar todas las cuentas que usualmente transfieres
                </p>
                <div class="image-mobile d-lg-none">
                    <div class="entities-list">
                        <ng-container *ngFor="let entity of entitiesArrayMobile">
                            <app-entity-card [id]="entity.id" [name]="entity.name"></app-entity-card>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <a href="https://app.neatpagos.com/registrar" title="Pagar cuentas" class="neat-button primary cta">Pagar cuentas</a>
                <a href="/more" title="Quiero saber más" class="neat-button secondary cta d-flex mt-3 ml-0" *ngIf="moreLink">Quiero saber más</a>
                <div class="arrow-down mobile" *ngIf="!moreLink">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 32.35L22 8L26 8L26 32.35L37.2 21.15L40 24L24 40L8 24L10.8 21.15L22 32.35Z" fill="#F04E98"/>
                    </svg>             
                </div>
            </div>
        </div>
        <div class="cards">
            <div class="images">
                <img src="assets/images/home/lg_visa.svg" alt="Visa">
                <img src="assets/images/home/lg_mastercard.svg" alt="Mastercard">
                <img src="assets/images/home/lg_amex.svg" alt="AMEX">
            </div>
            <p>Aceptamos tarjetas de crédito, débito y prepago emitidas en <span class="sr-only">Chile</span> <span>🇨🇱</span></p>
        </div>
    </div>
    <div class="image-desktop d-none d-lg-block">
        <div class="entities-cols">
            <div class="entities-list">
                <ng-container *ngFor="let entity of entitiesArrayDesktopCol1">
                    <app-entity-card [id]="entity.id" [name]="entity.name"></app-entity-card>
                </ng-container>
            </div>
            <div class="entities-list">
                <ng-container *ngFor="let entity of entitiesArrayDesktopCol2">
                    <app-entity-card [id]="entity.id" [name]="entity.name"></app-entity-card>
                </ng-container>
            </div>
            <div class="entities-list">
                <ng-container *ngFor="let entity of entitiesArrayDesktopCol3">
                    <app-entity-card [id]="entity.id" [name]="entity.name"></app-entity-card>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="arrow-down desktop" *ngIf="!moreLink">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 32.35L22 8L26 8L26 32.35L37.2 21.15L40 24L24 40L8 24L10.8 21.15L22 32.35Z" fill="#F04E98"/>
        </svg>             
    </div>
</section>