import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from './../../core/services/error-handler.service'
import { AppGlobals } from '@shared/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
@Injectable({
	providedIn: 'root'
})
export class CloudService {

	afFunction$: Observable<any>;
	loading: boolean;

	constructor(
		private http: HttpClient,
		private errorService: ErrorHandlerService,
	) {
		this.loading = false;
	}
	
	getArticleReaction(data: any): Observable<any> {
		let endpoint: string = AppGlobals.getArticleReactions;
		const httpHeader = {
		  headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		  }),
		  observe: "response" as 'body',
		  responseType: 'text' as 'json'
		};
		return this.http.post<any>(endpoint, data, httpHeader).pipe(
		  tap((_) => {
			// tslint:disable-next-line:no-console
			// console.info(_);
		  })
		);
	  }

	saveSurveyResponse(data: any): Observable<any> {
		let endpoint: string;
		endpoint = AppGlobals.saveSurveyResponse;
		const httpHeader = {
		  headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		  }),
		  observe: "response" as 'body',
		  responseType: 'text' as 'json'
		};
		return this.http.post<any>(endpoint, data, httpHeader).pipe(
		  tap((_) => {
			// tslint:disable-next-line:no-console
			// console.info(_);
		  })
		);
	  }

	  confirmPinAction(data: any): Observable<any> {
		let endpoint: string;
		endpoint = AppGlobals.confirmPinAction;
		const httpHeader = {
		  headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		  }),
		  observe: "response" as 'body',
		  responseType: 'text' as 'json'
		};
		return this.http.post<any>(endpoint, data, httpHeader).pipe(
		  tap((_) => {
			// tslint:disable-next-line:no-console
			// console.info(_);
		  })
		);
	  }

	blockingAccount(data: any): Observable<any> {
		let endpoint: string;
		endpoint = AppGlobals.blockingAccount;
		const httpHeader = {
		  headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		  }),
		  observe: "response" as 'body',
		  responseType: 'text' as 'json'
		};
		return this.http.post<any>(endpoint, data, httpHeader).pipe(
		  tap((_) => {
			// tslint:disable-next-line:no-console
			// console.info(_);
		  })
		);
	  }
	  requestUnblockingAccount(data: any): Observable<any> {
		let endpoint: string;
		endpoint = AppGlobals.requestUnblockingAccount;
		const httpHeader = {
		  headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		  }),
		  observe: "response" as 'body',
		  responseType: 'text' as 'json'
		};
		return this.http.post<any>(endpoint, data, httpHeader).pipe(
		  tap((_) => {
			// tslint:disable-next-line:no-console
			// console.info(_);
		  })
		);
	  }

	  unblockingAccount(data: any): Observable<any> {
		let endpoint: string;
		endpoint = AppGlobals.unblockingAccount;
		const httpHeader = {
		  headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		  }),
		  observe: "response" as 'body',
		  responseType: 'text' as 'json'
		};
		return this.http.post<any>(endpoint, data, httpHeader).pipe(
		  tap((_) => {
			// tslint:disable-next-line:no-console
			// console.info(_);
		  })
		);
	  }

	  userReactionToArticle(data: any): Observable<any> {
		  let endpoint: string = AppGlobals.userReactionToArticle;
		  const httpHeader = {
			headers: new HttpHeaders({
			  'Access-Control-Allow-Origin': '*',
			  'Content-Type': 'application/json',
			}),
			observe: "response" as 'body',
			responseType: 'text' as 'json'
		  };
		  return this.http.post<any>(endpoint, data, httpHeader).pipe(
			tap((_) => {
			  // tslint:disable-next-line:no-console
			  // console.info(_);
			})
		  );
		}

	  private handleError<T>(operation: string, result?: T) {
		return (error: any): Observable<T> => {
		  this.errorService.recordError(error, 'user.service.ts',
			operation, 'Error al procesar comunicación https');
		  // Let the app keep running by returning an empty result.
		  return of(result as T);
		};
	  }

}
