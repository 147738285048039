<footer class="footer">
    <div class="container-fluid">
        <div class="neat-links row">
            <dl class="col-12 col-lg-3">
                <dt><span>Empresa</span></dt>
                <dd><a [routerLink]="['/more/nosotros']" title="Equipo">Equipo</a></dd>
                <dd><a [routerLink]="['/neatclub']" title="NeatClub">NeatClub</a></dd>
                <dd><a [routerLink]="['/blog/articulos']" title="Aprende">Aprende</a></dd>
                <dd><a href="https://ayuda.neatpagos.com/es/" target="_blank" title="Ayuda">Ayuda</a></dd>
                <dt><span>Contacto</span></dt>
                <dd><a href="mailto:tengodudas@neatpagos.com">tengodudas@neatpagos.com</a></dd>
            </dl>
            <dl class="col-12 col-lg-3">
                <dt><span>Información legal</span></dt>
                <dd><a [routerLink]="['/more/terminos-y-condiciones']" target="_blank" title="Términos y condiciones NeatPagos">Términos y condiciones NeatPagos</a></dd>
                <dd><a [routerLink]="['/neatclub/terminos-y-condiciones-neatclub']" target="_blank" title="Términos y condiciones NeatClub">Términos y condiciones NeatClub</a></dd>
                <dd><a [routerLink]="['/more/politicas-de-privacidad']" target="_blank" title="Políticas de privacidad">Políticas de privacidad</a></dd>
            </dl>
            <dl class="col-12 col-lg-3">
                <dt><span>Seguridad</span></dt>
                <dd><a [routerLink]="['/more/bloquear-cuenta']" title="Bloqueo de emergencia">Bloqueo de emergencia</a></dd>
                <dd><a [routerLink]="['/more/desbloquear-cuenta']" title="Desbloqueo">Desbloqueo</a></dd>
            </dl>
            <dl class="col-12 col-lg-2 col-xl-3 offset-lg-1 offset-xl-0">
                <dt><span>Apoyados por</span></dt>
                <dd class="partners">
                    <a rel="noopener" href="https://numundoventures.com/" target="_blank"><img src="assets/images/footer/lg_numundo.svg" alt="NuMundo Ventures"></a>
                    <a rel="noopener" href="https://minga.ventures/" target="_blank"><img src="assets/images/footer/lg_minga.svg" alt="Minga Ventures"></a>
                    <a rel="noopener" href="https://www.fintechile.org/" target="_blank"><img src="assets/images/footer/lg_fintechile.svg" alt="FinteChile"></a>
                    <a rel="noopener" href="https://www.openbeauchef.cl/" target="_blank"><img src="assets/images/footer/lg_openbeauchef.svg" alt="OpenBeauchef"></a>
                    <a rel="noopener" href="https://www.corfo.cl/" target="_blank"><img src="assets/images/footer/lg_corfo.svg" alt="corfo"></a>
                </dd>
            </dl>
        </div>
        <div class="neat-info">
            <a [routerLink]="['/more']" title="Neat">
                <img src="assets/images/footer/lg_neat_footer_2023.svg" alt="Neat">
            </a>
            <p>© {{year}} neat SpA</p>
            <p *ngIf="devMode">{{version}}</p>
            <ul>
                <li><a rel="noopener" href="https://www.linkedin.com/company/neatpagos/" target="_blank"><img src="assets/images/footer/ic_linkedin.svg" alt="Síguenos en LinkedIn"></a></li>
                <li><a rel="noopener" href="https://www.instagram.com/neatpagos/" target="_blank"><img src="assets/images/footer/ic_instagram.svg" alt="Síguenos en Instagram"></a></li>
                <!-- <li><a rel="noopener" href="https://www.threads.net/@neatpagos" target="_blank"><img src="assets/images/footer/ic_threads.svg" alt="Síguenos en Threads"></a></li> -->
            </ul>
        </div>
    </div>
</footer>