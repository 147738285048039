import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID} from '@angular/core';
import { Router } from '@angular/router';
import { DevicesDetectorService } from '@shared/services/devices-detector.service';

@Component({
  selector: 'app-loading-landing',
  templateUrl: './loading-landing.component.html',
  styleUrls: ['./loading-landing.component.scss']
})
export class LoadingLandingComponent {
  loaded = false;
  display = false;

  constructor(
    private deviceDetectorService: DevicesDetectorService,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: any
    ) { 
      if (isPlatformBrowser(this.platformId)) { this.display = true}
      this.loaded = this.deviceDetectorService.isMobileVar || this.deviceDetectorService.isTabletVar || this.deviceDetectorService.isDesktopVar;
      if (this.deviceDetectorService.isDesktopVar) {
        this.router.navigate(['/more']);
      }
    }
  
}

  
