import { Injectable } from '@angular/core';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class SeoService {
  
  constructor(private meta: Meta, private titleService: Title, private sanitizer: DomSanitizer) { }

  generateTags(tags: any, noindex?: boolean): any {
    tags = {  
      title: 'Neat - Haz tus pagos mensuales con tarjeta y acumula sus beneficios',
      description: 'Además de tus cuentas básicas, puedes pagar todas las cuentas que usualmente transfieres',
      image: 'https://neatpagos.com/assets/neat-og.jpeg',
      slug: '',
      ...tags
    };
    if (noindex) {
      this.meta.addTags([{name: 'robots', content: 'noindex'}]);
    }
    this.titleService.setTitle(tags.title);
    this.meta.updateTag({name: 'title', content: tags.title});
    this.meta.updateTag({name: 'description', content: tags.description});
    this.meta.updateTag({name: 'og:type', content: 'website'});
    this.meta.updateTag({name: 'og:site_name', content: 'neat'});
    this.meta.updateTag({name: 'twitter:title', content: tags.title});
    this.meta.updateTag({name: 'twitter:description', content: tags.description});
    this.meta.updateTag({name: 'twitter:image', itemprop:'image', content: tags.image});
    this.meta.updateTag({name: 'twitter:url', content: 'https://neatpagos.com'});
    this.meta.updateTag({name: 'twitter:card', content: "summary_large_image"});
    this.meta.updateTag({name: 'twitter:image', content:  tags.image});
    this.meta.updateTag({name: 'twitter:image:alt', content: 'neat'});
    //linkedin metas
    this.meta.updateTag({property:'og:site_name', content: 'neat'});
    this.meta.updateTag({property:'og:type', content: 'website'});
    this.meta.updateTag({property:'og:title', content: tags.title});
    this.meta.updateTag({property:'og:description', content: tags.description});
    this.meta.updateTag({property:'og:url', content: 'https://neatpagos.com' + tags.url});
    this.meta.updateTag({property:'og:image', content: tags.image});
    //author metas
    if (tags.author && tags.readingTime) {
      this.meta.updateTag({name: 'twitter:label1', content: 'Escrito por ✏️'});
      this.meta.updateTag({name: 'twitter:data1', content: tags.author});
      this.meta.updateTag({name: 'twitter:label2', content: 'Tiempo de lectura ⏱'});
      this.meta.updateTag({name: 'twitter:data2', content: tags.readingTime});
    }
    
   }

   initializeTags() {
    this.titleService.setTitle('Neat - Haz tus pagos mensuales con tarjeta y acumula sus beneficios');
    this.meta.addTags([
      {name: 'title', content: 'Neat - Haz tus pagos mensuales con tarjeta y acumula sus beneficios' },
      {name: 'description', content: 'Además de tus cuentas básicas, puedes pagar todas las cuentas que usualmente transfieres' },
      {name: 'og:type', content: 'website'},
      {name: 'og:site_name', content: 'Neat'},
      {name: 'twitter:title', content: 'Neat - Haz tus pagos mensuales con tarjeta y acumula sus beneficios'},
      {name: 'twitter:description', content: 'Además de tus cuentas básicas, puedes pagar todas las cuentas que usualmente transfieres.'},
      {name: 'twitter:image', itemprop:'image', content: 'https://neatpagos.com/assets/neat-og.jpeg'},
      {name: 'twitter:url', content: 'https://neatpagos.com'},
      {name: 'twitter:card', content: 'summary_large_image'},
      {name: 'twitter:image', content: 'https://neatpagos.com/assets/neat-og.jpeg'},
      {name: 'twitter:image:alt', content: 'Neat'},
      {property:'og:site_name', content: 'Neat'},
      {property:'og:type', content: 'website'},
      {property:'og:title', content: 'Neat - Haz tus pagos mensuales con tarjeta y acumula sus beneficios'},
      {property:'og:description', content: 'Además de tus cuentas básicas, puedes pagar todas las cuentas que usualmente transfieres.'},
      {property:'og:url', content: 'https://neatpagos.com'},
      {property:'og:image', content: 'https://neatpagos.com/assets/neat-og.jpeg'}
    ]);
   }
 
   setJsonLD(jsonLD: {[key: string]: any}): SafeHtml {
    const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : ''; // escape / to prevent script tag in JSON
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}
