<section id="referrals-section" class="row animated fadeIn">
    <div class="col-12 col-md-12 mx-auto">
        <mat-card class="mat-elevation-z0 neumorphic">
            <mat-card-content class="px-lg-3">
                <span class="close" (click)="onNoClick()"><mat-icon class="c-pointer">close</mat-icon></span>
                <ng-container>
                    <div class="info-container">
                        <div style="display: block; max-width: 550px;">
                            <div class="dialog-header title">
                                <mat-card-title class="pt-2 pb-1 pb-md-3 px-md-2">Bienvenido a Neat &nbsp;🙌</mat-card-title>
                            </div>
                            <mat-card-subtitle>
                                <p>Felicidades, gracias a la referencia de tu amigo podrás disfrutar en tu primer pago un 25% de descuento en la comisión de Neat.</p>
                                <p>Más de 40.000 usuarios disfrutan los beneficios de pagar cuentas ganando flexibilidad, organización y beneficios.</p>          
                            </mat-card-subtitle>
                            <a mat-raised-button class="custom-btn px-4" style="width: auto;" (click)="onNoClick()" href="https://app.neatpagos.com/registrar" color="primary">Empezar</a>
                        </div>
                        <img class="img-fluid isabel d-none d-sm-block" width="640" src="assets/images/illustrations/referred-modal.png" alt="invited">
                    </div>
                </ng-container>
            </mat-card-content>
        </mat-card>
    </div>
</section>
