import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class DevicesDetectorService {
  public isMobileVar = false;
  public isDesktopVar = false;
  public isTabletVar = false;
  constructor(private deviceService: DeviceDetectorService) { }

  isMobile() {
    this.isMobileVar = this.deviceService.isMobile();
  }

  isDesktop() {
    this.isDesktopVar = this.deviceService.isDesktop();
  }

  isTablet() {
    this.isTabletVar = this.deviceService.isTablet();
  }
  
}
