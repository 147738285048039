import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';
@Injectable({
  providedIn: 'root',
})
export class ScrollService {

  constructor( 
     private pageScrollService: PageScrollService,
     @Inject(DOCUMENT) private document: any
     ) { }

  scrollToElement(section: string): void {
    this.pageScrollService.scroll({document: this.document, scrollTarget: `#${section}`, duration: 1000, scrollOffset: 70 });
  }

}
