import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, isDevMode, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: any
    ) { }

  setCookie(cname:string, cvalue: string, dname: string, exdays: number) {
    if (isPlatformBrowser(this.platformId)) {
      let d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      let expires = "expires="+ d.toUTCString();
      let domain = `domain=${dname}`;
      if (isDevMode()) {
        this.document.cookie = `${cname}=${cvalue};${expires};path=/`;
      } else {
        this.document.cookie = `${cname}=${cvalue};${domain};${expires};path=/`;
      }
    }
  }

  getCookie(cname:string): string {
    if (isPlatformBrowser(this.platformId)) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(this.document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
  }

}
