import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '@shared/services/dialog.service';
import { ScrollService } from '@shared/services/scroll.service';
@Component({
  selector: 'app-hero-image',
  templateUrl: './hero-image.component.html',
  styleUrls: ['./hero-image.component.scss']
})
export class HeroImageComponent  {
  routers: any;
  cont = 0;
  
  @Input() moreLink: boolean = false;

  entitiesArrayMobile = [
    { id: "0", name: "Jardín infantil" },
    { id: "11", name: "Agua" },
    { id: "9", name: "Servicios profesionales" },
    { id: "4", name: "Cable / Internet" },
    { id: "10", name: "Asesora del hogar" },
    { id: "5", name: "Gasto común" },
    { id: "1", name: "Arriendo" },
    { id: "2", name: "Luz" },
    { id: "6", name: "Gas" },
    { id: "7", name: "Estacionamiento" },
    { id: "3", name: "Gimnasio" },
    { id: "12", name: "Autopistas" },
    { id: "8", name: "Colegios" },
  ];

  
  entitiesArrayDesktopSet1 = [
    { id: "2", name: "Luz" },
    { id: "1", name: "Arriendo" },
    { id: "5", name: "Gasto común" },
    { id: "3", name: "Gimnasio" },
  ]
  entitiesArrayDesktopSet2 = [
    { id: "4", name: "Cable / Internet" },
    { id: "0", name: "Jardín infantil" },
    { id: "6", name: "Gas" },
    { id: "7", name: "Estacionamiento" },
    { id: "8", name: "Colegios" },
  ]
  entitiesArrayDesktopSet3 = [
    { id: "11", name: "Agua" },
    { id: "10", name: "Asesora del hogar" },
    { id: "12", name: "Autopistas" },
    { id: "9", name: "Servicios profesionales" },
  ]

  entitiesArrayDesktopCol1 = this.entitiesArrayDesktopSet1.concat(this.entitiesArrayDesktopSet2).concat(this.entitiesArrayDesktopSet3);

  entitiesArrayDesktopCol2 = this.entitiesArrayDesktopSet2.concat(this.entitiesArrayDesktopSet3).concat(this.entitiesArrayDesktopSet1);

  entitiesArrayDesktopCol3 = this.entitiesArrayDesktopSet3.concat(this.entitiesArrayDesktopSet1).concat(this.entitiesArrayDesktopSet2);

  constructor( 
    public scrollService: ScrollService, 
    private router: Router,
    private dialogService: DialogService,
    @Inject(PLATFORM_ID) private platformId: any
    ) {
      this.routers = router;
    }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event: any) => {
        if(event.url !== '/') {
            this.cont = 0;
        }
      });
    } 
  }

  openBankHoursModal() {
    this.dialogService.openBankHoursModal();
  }

}

  
