import { NavigationEnd, Router } from '@angular/router';
import { AfterViewInit, Component } from '@angular/core';
import { ScrollService } from '@shared/services/scroll.service';
import { CookiesService } from '@services/cookies.service';
import { filter } from 'rxjs/internal/operators/filter';
@Component({
  selector: 'app-shared-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit{
  isMenuCollapse = false;
  showCollapsed = false;
  currentUrl: string;
  isLoggedUser: string;
  constructor(
    public router: Router,
    public scrollService: ScrollService,
    private cookiesService: CookiesService,
  ) {
    this.isLoggedUser = this.cookiesService.getCookie('login') ? this.cookiesService.getCookie('login') : null;
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((res: NavigationEnd) => {
      this.currentUrl =  res.url;
    });
  }

  ngAfterViewInit(): void {
    this.showCollapsed = true;
  }

}
