import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-entity-card',
  templateUrl: './entity-card.component.html',
  styleUrls: ['./entity-card.component.scss']
})
export class EntityCardComponent implements OnInit {

  @Input() id: string
  @Input() name: string

  constructor() { }

  ngOnInit(): void { }

}
