// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const appVersion = require('./../../package.json').version;
export const environment = {
  production: false,
  neat_be: 'https://neatwebplatform-beta.appspot.com',
  neat_be_local: 'http://localhost:8080/api',
  firebase: {
    apiKey: 'AIzaSyASYMQur8Cd2oleCg8yOluYDb_NKXYQ3Yc',
    authDomain: 'neatwebplatform-beta.firebaseapp.com',
    databaseURL: 'https://neatwebplatform-beta.firebaseio.com',
    projectId: 'neatwebplatform-beta',
    storageBucket: 'neatwebplatform-beta.appspot.com',
    messagingSenderId: '83408358997',
    appId: '1:83408358997:web:099418f74040137a',
    measurementId: 'G-1QM0D4K1R8',
    fcmPublicKey: 'BO6E6IjSsUfQ2OY5fnJUF7gUk9bPQkNxSn634gBC2kzrYEw6LOFJCxsSUMLIv1ltwK-LBw7QAT-FaWYy0kUsycI'
  },
  sentry: {
    active: false,
    dsn: 'https://29ccfdf8e55a4a6f8667d8d20d6479b3@sentry.io/1807396',
    environment: 'development',
    release: 'neat-page@' + appVersion,
    tracesSampleRate: 0.25,
  },
  contentful: {
    spaceId: 'p9kah0gkki9i',
    token: 'PxI56Z6lyGGFe6eg5uPgHSfjao7txMjFdVcNouUYUMU',
    previewToken: 'jZeKiHWnWk78erueMA23o4X8gcZbIedZWzVmLCVn1Z4',
    environment: 'dev'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
