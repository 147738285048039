import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoadingLandingComponent } from './shared/components/loading-landing/loading-landing.component';
import { NoPageFoundComponent } from './shared/components/no-page-found/no-page-found.component';

const appRoutes: Routes = [
    { path: '', component: LoadingLandingComponent },
    {
        path: 'more',
        loadChildren: () => import('./components/landing.module').then(m => m.LandingModule)
    },
    {
        path: 'neatclub',
        loadChildren: () => import('./components/neatclub/neatclub.module').then(m => m.NeatClubModule)
    },
    {
        path: 'blog',
        loadChildren: () => import('./components/blog/blog.module').then(m => m.BlogModule)
    },
    { path: '**', component: NoPageFoundComponent }
];
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
        preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration: 'enabled',
        initialNavigation: 'enabled'
})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}